import { toHtml, icon } from "@fortawesome/fontawesome-svg-core"
import moment from "moment"
// eslint-disable-next-line import/no-cycle
import { siteLoadComplete } from "./actions/auth"
import redemptionBitsLogo from "./assets/images/redemption-bits-logo.png"
import ojoriBetsLogo from "./assets/images/ojori-bets-logo.png"
import redemptionBitsLogoSpinner from "./assets/icons/redemption-bits-spinner-logo.svg"
import jineneBetsSpinnerLogo from "./assets/icons/spinner-logo-jinene-bets.svg"
import billIconCarbonGaming from "./assets/icons/bill-icon-carbongaming.png"
import billIconRedemptionBits from "./assets/icons/bill-icon-carbongames.png"
import giftBoxCarbonGaming from "./assets/images/home/gift-box-carbongaming.png"
import giftBoxRedemptionBits from "./assets/images/home/gift-box-carbongames.png"
import arrowRightCarbonGaming from "./assets/icons/arrow-right-carbongaming.svg"
import arrowRightRedemptionBits from "./assets/icons/arrow-right-carbongames.png"
import playButtonCarbonGaming from "./assets/images/cards/play-button-carbongaming.png"
import playButtonRedemptionBits from "./assets/images/cards/play-button-redemption-bits.png"
import aboutUsAssetCarbonGaming from "./assets/images/home/about-us-asset-carbon-gaming.png"
import aboutUsRedemptionBits from "./assets/images/home/about-us-asset-redemption-bits.png"
import customArrowCarbonGaming from "./assets/images/depositAssets/custom-accordion-arrow-carbon-gaming.png"
import customArrowRedemptionBits from "./assets/images/depositAssets/custom-accordion-arrow-carbon-games.png"
import checkMarkCarbonGaming from "./assets/icons/check-mark-carbon-gaming.svg"
import checkMarkRedemptionBits from "./assets/icons/check-mark-redemption-bits.svg"
import referralAssetCarbonGaming from "./assets/images/affiliateModal/referral-asset-carbon-gaming.png"
import referralAssetRedemptionBits from "./assets/images/affiliateModal/referral-asset-redemption-bits.png"
import referrerAssetCarbonGaming from "./assets/images/affiliateModal/referrer-asset-carbon-gaming.png"
import referrerAssetRedemptionBits from "./assets/images/affiliateModal/referrer-asset-redemption-bits.png"
import bonusCodeAssetCarbonGaming from "./assets/images/bonusCode/activate-bonus-asset-carbon-gaming.png"
import bonusCodeAssetRedemptionBits from "./assets/images/bonusCode/activate-bonus-asset-redemption-bits.png"
import depositBonusAssetCarbonGaming from "./assets/images/home/money-bag-carbon-gaming.png"
import depositBonusAssetRedemptionBits from "./assets/images/home/money-bag-redemption-bits.png"
import levelRewardAssetCarbonGaming from "./assets/images/bonusCode/activated-bonus-asset-carbon-gaming.png"
import levelRewardAssetRedemptionBits from "./assets/images/bonusCode/activated-bonus-asset-redemption-bits.png"
import signInModalAssetCarbonGaming from "./assets/images/modalAssets/sign-in-modal-asset-carbon-gaming.png"
import signInModalAssetRedemptionBits from "./assets/images/modalAssets/sign-in-modal-asset-redemption-bits.png"
import registerModalAssetNewCarbonGaming from "./assets/images/modalAssets/register-modal-asset-new-carbon-gaming.png"
import registerModalAssetNewRedemptionBits from "./assets/images/modalAssets/register-modal-asset-new-redemption-bits.png"
import recoveryEmailAssetCarbonGaming from "./assets/images/modalAssets/recovery-email-modal-asset-carbon-gaming.png"
import recoveryEmailAssetRedemptionBits from "./assets/images/modalAssets/recovery-email-modal-asset-redemption-bits.png"
import resetPasswordAssetCarbonGaming from "./assets/images/modalAssets/reset-password-modal-asset-carbon-gaming.png"
import resetPasswordAssetRedemptionBits from "./assets/images/modalAssets/reset-password-modal-asset-redemption-bits.png"
import phoneNumberVerificationAssetCarbonGaming from "./assets/images/modalAssets/register-modal-phone-number-verification-asset-carbon-gaming.png"
import phoneNumberVerificationAssetRedemptionBits from "./assets/images/modalAssets/register-modal-phone-number-verification-asset-redemption-bits.png"
import registerModalAssetCarbonGaming from "./assets/images/modalAssets/register-modal-asset-carbon-gaming.png"
import registerModalAssetRedemptionBits from "./assets/images/modalAssets/register-modal-asset-redemption-bits.png"
import arrowLeftDirectionCarbonGaming from "./assets/icons/arrow-left-direction-carbon-gaming.svg"
import arrowLeftDirectionRedemptionBits from "./assets/icons/arrow-left-direction-redemption-bits.svg"
import vipBannerAssetCarbonGaming from "./assets/images/home/background-assets-bonus-banner-carbongaming.png"
import vipBannerAssetRedemptionBits from "./assets/images/home/background-assets-bonus-banner-carbongames.png"
// import logoNewCarbonGaming from "./assets/images/logo-new-carbon-gaming.svg"
import logoNewRedemptionBits from "./assets/images/logo-new-redemption-bits.png"
import logoNewJineneBets from "./assets/images/logo-new-jinen-bets.svg"
// import gamesCatalogTopAssetCarbonGaming from "./assets/images/games-catalog-top-asset.png"
import gamesCatalogTopAssetRedemptionBits from "./assets/images/games-catalog-top-asset-redemption-bits.png"
import gamesCtalogtopAssetOjoriBets from "./assets/images/games-catalog-top-asset-ojori-bits.png"
import aboutUsBackgroundCarbonGaming from "./assets/images/home/hero-bg-carbon-gaming.png"
import aboutUsBackgroundRedemptionBits from "./assets/images/home/hero-bg-carbon-games.png"
import themes from "./themes"

export default (faIcon, color) => {
  const abstract = icon(faIcon).abstract[0]
  if (color) abstract.children[0].attributes.fill = color
  return `data:image/svg+xml;base64,${btoa(toHtml(abstract))}`
}

export const copyTextToClipboard = (text) => {
  if ("clipboard" in navigator) {
    navigator.clipboard.writeText(text)
  } else {
    document.execCommand("copy", true, text)
  }
}

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
})

export const loadScript = (src, id, callback) => {
  const existingScript = document.getElementById(id)

  if (!existingScript) {
    const script = document.createElement("script")
    script.id = id
    script.async = false
    script.src = src

    document.body.appendChild(script)

    script.onload = () => {
      if (callback) callback()
    }
  }

  if (existingScript && callback) callback()
}

export const removeScript = (id) => {
  const script = document.getElementById(id)

  if (script) script.remove()
}

// custom function to log data
// eslint-disable-next-line consistent-return
export const logger = (...args) => {
  if (process.env.REACT_APP_CONTEXT === "production") {
    return null
  }

  args.forEach((arg, index) => {
    if (index % 2 === 0) {
      const message = arg
      const data = args[index + 1]
      if (data !== undefined) {
        console.log(message, data)
      } else {
        console.log(message)
      }
    }
  })
}

export const formatDate = (dateNoFormat) => {
  const date = moment(dateNoFormat, "DD-MM-YYYY, HH:mm:ss")
  return date.format("YYYY-MM-DD HH:mm:ss")
}

export const handleEnjectImageLoad = (dispatch) => {
  Promise.all(
    Array.from(document.images).map((img) => {
      if (img.complete) return Promise.resolve(img.naturalHeight !== 0)
      return new Promise((resolve) => {
        img.addEventListener("load", () => resolve(true))
        img.addEventListener("error", () => resolve(false))
      })
    })
  ).then((results) => {
    if (results.every((res) => res)) dispatch(siteLoadComplete())
    else logger("some images failed to load, all finished loading")
  })
}

export const generateUsername = () => {
  const adjectives = [
    "lucky",
    "joker",
    "slick",
    "witty",
    "banou",
    "clev",
    "clemence",
    "Silou",
    "witten",
    "gilock",
  ]
  const nouns = [
    "Jack",
    "Antthony",
    "Fiera",
    "Saliman",
    "Widouj",
    "Caprice",
    "Capricorn",
    "Fernand",
    "Midas",
    "Kalim",
  ]

  const randomAdjective =
    adjectives[Math.floor(Math.random() * adjectives.length)]
  const randomNoun = nouns[Math.floor(Math.random() * nouns.length)]

  return randomNoun + randomAdjective
}

export const generatePayout = () => {
  const integerPart = Math.floor(Math.random() * 501)
  const decimalPart = Math.floor(Math.random() * 100)
  const formattedNumber = `${integerPart},${decimalPart
    .toString()
    .padStart(2, "0")}`
  return formattedNumber
}

export const generateMultiplier = () => {
  const integerPart = Math.floor(Math.random() * 4)
  const decimalPart = Math.floor(Math.random() * 6)
  const number = integerPart + decimalPart / 10
  return number
}

export const generateFakeDates = () => {
  const currentDate = new Date()
  const maxDate = new Date()
  maxDate.setDate(maxDate.getDate() - 7)

  const randomTimestamp =
    Math.random() * (currentDate.getTime() - maxDate.getTime()) +
    maxDate.getTime()
  const randomDate = new Date(randomTimestamp)

  const randomHour = Math.floor(Math.random() * 24)
  const randomMinute = Math.floor(Math.random() * 60)

  randomDate.setHours(randomHour)
  randomDate.setMinutes(randomMinute)

  const formattedDate = randomDate.toISOString().split(".")[0]
  return formattedDate
}

export const generateWager = () => Math.floor(Math.random() * 21) + 20

export const generateGamesPayoutsData = () => {
  const multiplier = Math.floor(Math.random() * 26) + 5
  const wager = Math.floor(Math.random() * 91) + 10
  return { multiplier, wager }
}

// New functions
export const pickRandomUsers = (array) => {
  if (!Array.isArray(array)) {
    return []
  }

  const shuffledArray = array.sort(() => 0.5 - Math.random())
  const randomObjects = shuffledArray.slice(0, 5)

  const sortedObjects = randomObjects.sort((a, b) => {
    const payoutA = parseInt(a.payout.replace(/\$/g, ""), 10)
    const payoutB = parseInt(b.payout.replace(/\$/g, ""), 10)
    return payoutB - payoutA
  })

  return sortedObjects
}

const carbonGamingAlias = [
  "www.carbongaming.net",
  "www.jinenebet.com",
  "www.ojoribet.com",
]

export const getBrandFromUrl = () => {
  let brand
  const defaultBrand = "www.carbongaming.net"

  if (process.env.REACT_APP_CONTEXT === "production") {
    // Check if the navigator version os headless or not
    const isHeadless = navigator.webdriver
    const hostname = window.location.hostname || defaultBrand
    brand = hostname.startsWith("www.") ? hostname : `www.${hostname}`
    if (carbonGamingAlias.includes(brand) || isHeadless) {
      brand = "www.carbongaming.net"
    }
  } else {
    const hostname = "redemptionbits.com"
    brand = hostname.startsWith("www.") ? hostname : `www.${hostname}`
    if (carbonGamingAlias.includes(brand)) {
      brand = "www.carbongaming.net"
    }
  }

  return brand || defaultBrand
}

// Function that detect the brand and return the brand name
export const promptBrand = (arg) => {
  const brand = getBrandFromUrl()
  const hostname = brand ? brand.replace(/^www\./, "") : ""
  const name = hostname.split(".")[0].replace(/-/g, " ")

  switch (arg) {
    case "name":
      if (name.toLowerCase() === "redemptionbits") {
        return "Redemption Bits"
      }
      return "Ojori Bets"
        .split(" ")
        .map((word) =>
          word ? word[0].toUpperCase() + word.substring(1).toLowerCase() : ""
        )
        .join(" ")

    case "site":
      if (hostname.includes("redemptionbits")) {
        return "redemptionbits.com"
      }
      return "ojoribet.com"

    // return hostname

    default:
      return hostname
  }
}

export const getThemeColors = () => {
  let themeColors = {}

  themeColors = themes[getBrandFromUrl()].colors
  return themeColors
}

// Distribution of brands
export const brandLogo = () => {
  const brand = getBrandFromUrl()

  const logos = {
    "www.carbongaming.net": ojoriBetsLogo,
    "www.redemptionbits.com": redemptionBitsLogo,
  }

  return logos[brand]
}

export const logoSpinner = {
  "www.carbongaming.net": jineneBetsSpinnerLogo,
  "www.redemptionbits.com": redemptionBitsLogoSpinner,
}

// Distribution of images

export const imageDistributed = (imageProp) => {
  const brand = getBrandFromUrl()

  const images = {
    billIcon: {
      "www.carbongaming.net": billIconCarbonGaming,
      "www.redemptionbits.com": billIconRedemptionBits,
    },
    giftBox: {
      "www.carbongaming.net": giftBoxCarbonGaming,
      "www.redemptionbits.com": giftBoxRedemptionBits,
    },
    loadMoreArrow: {
      "www.carbongaming.net": arrowRightCarbonGaming,
      "www.redemptionbits.com": arrowRightRedemptionBits,
    },
    gamePlayButton: {
      "www.carbongaming.net": playButtonCarbonGaming,
      "www.redemptionbits.com": playButtonRedemptionBits,
    },
    aboutUsAsset: {
      "www.carbongaming.net": aboutUsAssetCarbonGaming,
      "www.redemptionbits.com": aboutUsRedemptionBits,
    },
    customArrowUp: {
      "www.carbongaming.net": customArrowCarbonGaming,
      "www.redemptionbits.com": customArrowRedemptionBits,
    },
    checkMark: {
      "www.carbongaming.net": checkMarkCarbonGaming,
      "www.redemptionbits.com": checkMarkRedemptionBits,
    },
    referralAsset: {
      "www.carbongaming.net": referralAssetCarbonGaming,
      "www.redemptionbits.com": referralAssetRedemptionBits,
    },
    referrerAsset: {
      "www.carbongaming.net": referrerAssetCarbonGaming,
      "www.redemptionbits.com": referrerAssetRedemptionBits,
    },
    bonusCodeAsset: {
      "www.carbongaming.net": bonusCodeAssetCarbonGaming,
      "www.redemptionbits.com": bonusCodeAssetRedemptionBits,
    },
    depositBonusAsset: {
      "www.carbongaming.net": depositBonusAssetCarbonGaming,
      "www.redemptionbits.com": depositBonusAssetRedemptionBits,
    },
    levelRewardPopup: {
      "www.carbongaming.net": levelRewardAssetCarbonGaming,
      "www.redemptionbits.com": levelRewardAssetRedemptionBits,
    },
    signInModalAsset: {
      "www.carbongaming.net": signInModalAssetCarbonGaming,
      "www.redemptionbits.com": signInModalAssetRedemptionBits,
    },
    registerModalAsset: {
      "www.carbongaming.net": registerModalAssetNewCarbonGaming,
      "www.redemptionbits.com": registerModalAssetNewRedemptionBits,
    },
    recoveryEmailAsset: {
      "www.carbongaming.net": recoveryEmailAssetCarbonGaming,
      "www.redemptionbits.com": recoveryEmailAssetRedemptionBits,
    },
    resetPasswordAsset: {
      "www.carbongaming.net": resetPasswordAssetCarbonGaming,
      "www.redemptionbits.com": resetPasswordAssetRedemptionBits,
    },
    phoneVerificationView: {
      "www.carbongaming.net": phoneNumberVerificationAssetCarbonGaming,
      "www.redemptionbits.com": phoneNumberVerificationAssetRedemptionBits,
    },
    registerModalAssetImage: {
      "www.carbongaming.net": registerModalAssetCarbonGaming,
      "www.redemptionbits.com": registerModalAssetRedemptionBits,
    },
    arrowLeftDirectionAsset: {
      "www.carbongaming.net": arrowLeftDirectionCarbonGaming,
      "www.redemptionbits.com": arrowLeftDirectionRedemptionBits,
    },
    vipBannerAsset: {
      "www.carbongaming.net": vipBannerAssetCarbonGaming,
      "www.redemptionbits.com": vipBannerAssetRedemptionBits,
    },
    logoNew: {
      "www.carbongaming.net": logoNewJineneBets,
      "www.redemptionbits.com": logoNewRedemptionBits,
    },
    gamesCatalogTopAsset: {
      "www.carbongaming.net": gamesCtalogtopAssetOjoriBets,
      "www.redemptionbits.com": gamesCatalogTopAssetRedemptionBits,
    },
    aboutUsBackground: {
      "www.carbongaming.net": aboutUsBackgroundCarbonGaming,
      "www.redemptionbits.com": aboutUsBackgroundRedemptionBits,
    },
  }

  return images[imageProp][brand]
}
